import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos'

import Layout from '../../../components/Layout'
import Section from '../../../components/Section'
import Hero from '../../../components/Hero'
import FeaturedPlatformPagesSection from '../../../components/FeaturedPlatformPagesSection'
import LatestResourcesSection from '../../../components/LatestResourcesSection'
import FaqSection from '../../../components/FaqSection'
import CustomerStorySliderSection from '../../../components/CustomerStorySliderSection'
import BookDemoSection from '../../../components/BookDemoSection'
import { bookADemoLinkSwitch } from '../../../components/lib/bookADemoLinkSwitch'

import home2022_1 from '../../../images/features2023/hr-compliance-and-advisory/Feature_Images/MyHR-Platform-Guided Customer Onboarding AU@2x.png'
import home2022_2 from '../../../images/features2023/hr-compliance-and-advisory/Feature_Images/MyHR-Platform-Employment Law and Legal Compliance AU@2x.png'
import home2022_3 from '../../../images/features2023/hr-compliance-and-advisory/Feature_Images/MyHR-Platform-On call advisory team@2x.png'
import home2022_4 from '../../../images/features2023/hr-compliance-and-advisory/Feature_Images/MyHR-Platform-Employment relations support@2x.png'
import bannerIcon1 from '../../../images/features2023/hiring-and-onboarding/Top_Banner_Icons/23-129_MyHR_Onboarding_Platform_Page_Employee-experience.png'
import bannerIcon2 from '../../../images/features2023/performance-reviews/Top_Banner_Icons/23-140_MyHR_SupportAsset 1.png'
import bannerIcon3 from '../../../images/features2023/hr-compliance-and-advisory/Top_Banner_Icons/23-140_MyHR_Compliant.png'
import customerStories from '../../../data/customerStories'

import '../../platform.scss';

const PAGE_CONFIG = {
  locale: "en-AU",
  meta: {
    title: "HR Advisory Services - Get On-Call HR Advice",
    description: "Get the best outcomes for your business with our HR compliance software and on-call advisory team. We offer support tailored to your business. Get started now."
  },
  tourLink: "https://app.storylane.io/share/khkknhixy6ug",
}


export default () => {
  const demoLink = bookADemoLinkSwitch(PAGE_CONFIG.locale);

  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  return (
    <Layout
      className="PlatformPage"
      title={PAGE_CONFIG.meta.title}
      description={PAGE_CONFIG.meta.description}
      locale={PAGE_CONFIG.locale}
    >
      <Hero variant="hr-compliance-and-advisory">
        <h1>On-call advice <br/>from HR experts</h1>
        <p>
          Talk to an HR expert for practical and personalised HR advice,<br className="d-none d-lg-inline"/> from admin to complex HR issues.
        </p>
        <Button
          color="cyan"
          href={demoLink}
          className="mr-3"
        >
          Book a Demo
        </Button>
        <Button
          color="outline-cyan"
          href={PAGE_CONFIG.tourLink}
          target="_blank"
        >
          <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
          Take advisory tour
        </Button>
      </Hero>

      <Section className="BannerIconSection">
        <Container>
          <Row className="">
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon1} />
              <p>Support tailored to your business's unique needs</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon2} />
              <p>Handle tricky people issues with confidence</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon3} />
              <p>Get unbiased advice and recommendations that empower you to make good business decisions</p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="Content greyBack">
        <Container>
          <Row className="mt-5 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>Expert HR advice</h4>
              <h3>Your go-to for no-nonsense <br className="d-none d-lg-inline"/>HR support</h3>
              <p>
                Beyond MyHR’s software, meet our team: experienced HR professionals ready to deliver top-notch support and practical advice for any employment challenge or process. Our team of HR experts provide practical and effective solutions to all your people-related problems. Our proactive support grows with your organisation, ensuring confidence in every HR decision you make.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Try a demo <span>&rarr;</span></a>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_1} />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_2} />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>Stay compliant</h4>
              <h3>Compliance, clarity, control</h3>
              <p>
                Australian employment law is complicated, from the Fair Work Act to modern awards and registered agreements. We conduct top-to-bottom HR audits, spot gaps, make recommendations, and provide updated policies and agreements, keeping you fully compliant, with control and clarity throughout.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Try a demo <span>&rarr;</span></a>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>Reduce admin</h4>
              <h3>Reclaim time for what matters</h3>
              <p>
                Our team is an extension of yours. We handle the day-to-day questions, noise, documents, and hassles, so you can focus on big-picture strategies and get time back in your day.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Try a demo <span>&rarr;</span></a>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_3} />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_4} />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>Navigate tricky HR</h4>
              <h3>Tackle tough HR issues <br className="d-none d-lg-inline"/>with confidence</h3>
              <p>
                When employment relationships get thorny, our HR experts will guide you through any conversation, process, or situation. From trial period terminations to gnarly counselling & disciplinaries, and company restructuring. We provide tailored, step-by-step advice (in plain English!) so that any change or problem is handled fairly and legally.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Try a demo <span>&rarr;</span></a>
            </Col>
          </Row>
        </Container>
      </Section>

      <CustomerStorySliderSection
        stories={[
          {
            ...customerStories['roosters'],
            "moreStoriesButton": true
          }
        ]}
      />

      <FeaturedPlatformPagesSection title="Check out our other features"/> 

      <LatestResourcesSection />


      <FaqSection 
        title="HR compliance & Advisory FAQs"
        items={[
          {
            question: "If I already have internal HR, is MyHR the right fit for my organisation?",
            answer: (
              <p>Absolutely. Whether your HR person is a seasoned professional or someone who’s earlier in their career, we’ll feel just like an extension of your team.</p>
            )
          },
          {
            question: "Can I call and ask for help during an organisational restructure?",
            answer: (
              <>
                <p>That’s what we’re here for. We manage between five and ten restructures a week for our clients, so there’s not a lot we haven’t seen.</p>
                <p>Once you’ve got your business case, let us know - we’ll write the consultation documents for your employees, guide you through the process, help you manage any spanners that get thrown your way, and confirm any redundancies with care and respect.</p>
              </>
            )
          },
          {
            question: "I’ve got a small team without any gnarly issues. What would MyHR offer me?",
            answer: (
              <>
                <p>We work with organisations of all sizes - our smallest client has one employee!</p>
                <p>Whether you’ve got five employees or 50, you’re still subject to employment law and will have documentation to manage, which is where we shine.</p>
                <p>We’ll set you up with a MyHR account, where you can store your employee records safely and access them anywhere, at any time. We’ll stay on top of changing employment law and let you know if any of your policies or practices need to change (trial periods, anyone?) so that you can focus on your business.</p>
                <p>And, if you do end up having a run-in with one of your team, our HR experts are only a phone call away. </p>
              </>
            )
          },
          {
            question: "What’s the limit on the number of calls I can make? ",
            answer: (
              <>
                <p>None at all!</p>
                <p>We don’t cap our services - some months, you’ll get in touch once or twice, and the next month, we might spend a few hours on the phone to help you manage a tricky issue.</p>
                <p>We’re your outsourced HR team. Get in touch whenever you need us.</p>
              </>
            )
          }
        ]}
      />

      <BookDemoSection
        title="Make HR easy"
        text={(
          <p>Get an HR expert backing you through any process or employment issue.</p>
        )}
        variant="compact"
        showTrialButton={false}
        extraButtons={
          <Button
            color="outline-cyan"
            href={PAGE_CONFIG.tourLink}
            target="_blank"
          >
            <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
            Take the tour
          </Button>
          }
      />
    </Layout>
  )
}
